import { Component, OnInit, EventEmitter, Output, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RequesterService } from '../../../common/services/requester.service';
import {
  NzMessageService
} from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
class Category {
  categoryName: string;
  categoryId: number;
  value: number;
  label: string;
  selected: boolean;
  isLeaf: boolean;
  verified: boolean;
  children: Category[];
  constructor(data) {
    this.categoryName = data.categoryName;
    this.label = data.categoryName;
    this.categoryId = Number(data.categoryId);
    this.value = Number(data.categoryId);
    this.isLeaf = data.isLeaf || false;
    this.selected = data.selected || false;
    this.children = data.children || [];
    this.verified = data.verified === undefined ? true : data.verified;
  }
}
@Component({
  selector: 'app-company-skills',
  templateUrl: './company-skills.component.html',
  styleUrls: ['./company-skills.component.scss']
})
export class CompanySkillsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private requesterService: RequesterService,
    private messageService: NzMessageService
  ) {
  }
  perPageLimit = 50;
  companySkillForm: FormGroup;
  isLoading = false;
  value: any[];
  @Output() formData = new EventEmitter();
  brandSearch: string | null = null;
  brandSearchObs = new Subject();

  serviceSearch: string | null = null;
  serviceSearchObs = new Subject();

  equipmentSearch: string | null = null;
  equipmentSearchObs = new Subject();

  allBrands: any[] = [];
  mySelectedBrands: string[] = [];

  allServices: any[] = [];
  mySelectedServices: string[] = [];

  allEquipments: any[] = [];
  mySelectedEquipments: string[] = [];

  selectedTabIndex = 0;
  brandsPage = 1;
  servicesPage = 1;
  equipmentsPage = 1;

  showLoadMoreBrand = false;
  showLoadMoreService = false;
  showLoadMoreEquipment = false;

  addServiceTypePopupOpen = false;
  addBrandPopupOpen = false;
  addEquipmentPopupOpen = false;


  newBrandInput = '';
  newServiceInput = '';
  newEquipmentInput = '';

  addServiceLoading = false;
  addEquipmentLoading = false;
  addBrandLoading = false;
  companyDetails: any = {};

  async ngOnInit() {
    this.companySkillForm = this.fb.group({
      serviceTypes: [[]],
      equipmentTypes: [[]],
      brands: [[]]
    });
    this.companyDetails = await this.getMyCompanyDetails();
    this.mySelectedServices = this.companyDetails.skills && this.companyDetails.skills.serviceTypes ?
    this.companyDetails.skills.serviceTypes : [];
    this.mySelectedEquipments = this.companyDetails.skills && this.companyDetails.skills.equipmentTypes ?
    this.companyDetails.skills.equipmentTypes : [];
    this.mySelectedBrands = this.companyDetails.skills && this.companyDetails.skills.brands ?
    this.companyDetails.skills.brands : [];
    this.updateSelectedServiceTypes();
    this.updateSelectedEquipmentTypes();
    this.updateSelectedBrands();
    this.formData.emit(this.companySkillForm);

    this.getBrands();
    this.getServices();
    this.getEquipments();
    this.brandSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      console.log(searched);
      this.getBrands();
    });


    this.serviceSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      console.log(searched);
      this.getServices();
    });


    this.equipmentSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      console.log(searched);
      this.getEquipments();
    });



  }


  async getMyCompanyDetails(): Promise<any> {
    try {
      const { data } = await this.requesterService.request('get', 'vendor-companies/my-company').toPromise();
      return data;
    } catch (e) {
      console.log(e);
    }
  }



  getBrands(more = false) {
    this.isLoading = true;
    if (more) {
      this.brandsPage++;
    } else {
      this.allBrands = [];
      this.brandsPage = 1;
    }
    const params: any = {
      skip: ( this.brandsPage - 1 ) * this.perPageLimit,
      limit: this.perPageLimit
    };
    if (this.brandSearch) {
      params.search = this.brandSearch;
    }
    this.requesterService.request('get', `master-resources/brands`, params).subscribe(data => {
      this.showLoadMoreBrand = data.data.length >= this.perPageLimit;
      this.allBrands = [...this.allBrands, ...data.data];
      // console.log('allBrands', this.allBrands)
      this.isLoading = false;
      this.allBrands.forEach( item => {
        item.selected = this.mySelectedBrands.includes( item.brandName );
      });
    });
  }



  getServices(more = false) {
    this.isLoading = true;
    if (more) {
      this.servicesPage++;
    } else {
      this.allServices = [];
      this.servicesPage = 1;
    }
    const params: any = {
      skip: ( this.servicesPage - 1 ) * this.perPageLimit,
      limit: this.perPageLimit
    };
    if (this.serviceSearch) {
      params.search = this.serviceSearch;
    }
    this.requesterService.request('get', `master-resources/services`, params).subscribe(data => {
      this.showLoadMoreService = data.data.length >= this.perPageLimit;
      this.allServices = [...this.allServices, ...data.data];
      this.isLoading = false;
      this.allServices.forEach( item => {
        item.selected = this.mySelectedServices.includes( item.serviceName );
      });
    });
  }


  getEquipments(more = false) {
    this.isLoading = true;
    if (more) {
      this.equipmentsPage++;
    } else {
      this.allEquipments = [];
      this.equipmentsPage = 1;
    }
    const params: any = {
      skip: ( this.equipmentsPage - 1 ) * this.perPageLimit,
      limit: this.perPageLimit
    };
    if (this.equipmentSearch) {
      params.search = this.equipmentSearch;
    }
    this.requesterService.request('get', `master-resources/equipment`, params).subscribe(data => {
      this.showLoadMoreEquipment = data.data.length >= this.perPageLimit;
      this.allEquipments = [...this.allEquipments, ...data.data];
      this.isLoading = false;
      this.allEquipments.forEach( item => {
        item.selected = this.mySelectedEquipments.includes( item.equipmentName );
      });
    });
  }

  onBrandSearch(e) {
    this.brandSearchObs.next(e);
    this.newBrandInput = e;
  }
  onServiceSearch(e) {
    console.log('on Service change', e);
    this.serviceSearchObs.next(e);
    this.newServiceInput = e;
  }
  onEquipmentSearch(e) {
    this.equipmentSearchObs.next(e);
    this.newEquipmentInput = e;
  }

  async addService(): Promise<void> {
    try {
      const {data} = await this.requesterService
      .request('post', 'master-resources/services', {serviceName: this.newServiceInput}).toPromise();
      // this.mySelectedServices.push(this.newServiceInput);
      this.allServices.push(data);
      this.addToMyServices(0);
    } catch (e) {

    }

  }
  async addEquipment(): Promise<void> {
    try {
      const {data} = await this.requesterService
      .request('post', 'master-resources/equipment', {equipmentName: this.newEquipmentInput}).toPromise();

      this.allEquipments.push(data);
      this.addToMyEquipments(0);
    } catch (e) {

    }
  }
  async addBrand(): Promise<void> {
    try {
      const {data} = await this.requesterService
      .request('post', 'master-resources/brands', {brandName: this.newBrandInput}).toPromise();

      this.allBrands.push(data);
      this.addToMyBrands(0);
    } catch (e) {

    }
  }


  addToMyBrands(pos: number) {
    const itemToPush = { ...this.allBrands[pos] };
    const found = this.mySelectedBrands.indexOf(itemToPush.brandName);
    if (found === -1) {
      this.mySelectedBrands.push(itemToPush.brandName);
    } else {
      this.mySelectedBrands.splice(found, 1);
    }
    this.updateSelectedBrands();
  }
  addToMyServices(pos: number) {
    const itemToPush = { ...this.allServices[pos] };
    const found = this.mySelectedServices.indexOf(itemToPush.serviceName);
    if (found === -1) {
      this.mySelectedServices.push(itemToPush.serviceName);
    } else {
      this.mySelectedServices.splice(found, 1);
    }
    this.updateSelectedServiceTypes();
  }

  addToMyEquipments(pos: number) {
    const itemToPush = { ...this.allEquipments[pos] };
    const found = this.mySelectedEquipments.indexOf(itemToPush.equipmentName);
    if (found === -1) {
      this.mySelectedEquipments.push(itemToPush.equipmentName);
    } else {
      this.mySelectedEquipments.splice(found, 1);
    }
    this.updateSelectedEquipmentTypes();
  }


  removeFromMyBrands(pos: number) {
    const itemToRevert = this.mySelectedBrands[pos];
    const foundIndex = this.allBrands.findIndex(x => x.brandName === itemToRevert);
    this.mySelectedBrands.splice(pos, 1);
    if (foundIndex !== -1) {
      this.allBrands[foundIndex].selected = false;
    }
    this.updateSelectedBrands();
  }
  removeFromMyServices(pos: number) {
    const itemToRevert = this.mySelectedServices[pos];
    const foundIndex = this.allServices.findIndex(x => x.serviceName === itemToRevert);
    this.mySelectedServices.splice(pos, 1);
    if (foundIndex !== -1) {
      this.allServices[foundIndex].selected = false;
    }
    this.updateSelectedServiceTypes();
  }
  removeFromMyEquipments(pos: number) {
    const itemToRevert = this.mySelectedBrands[pos];
    const foundIndex = this.allEquipments.findIndex(x => x.equipmentName === itemToRevert);
    this.mySelectedEquipments.splice(pos, 1);
    if (foundIndex !== -1) {
      this.allEquipments[foundIndex].selected = false;
    }
    this.updateSelectedEquipmentTypes();
  }


  updateSelectedServiceTypes() {
    this.companySkillForm.patchValue({ serviceTypes: this.mySelectedServices });
    this.allServices.map( x => x.selected = this.mySelectedServices.includes(x.serviceName));
  }
  updateSelectedEquipmentTypes() {
    this.companySkillForm.patchValue({ equipmentTypes: this.mySelectedEquipments });
    this.allEquipments.map( x => x.selected = this.mySelectedEquipments.includes(x.equipmentName));

  }

  updateSelectedBrands() {
    this.companySkillForm.patchValue({ brands: this.mySelectedBrands });
    this.allBrands.map( x => x.selected = this.mySelectedBrands.includes(x.brandName));

  }



}
