import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { FloatingChatComponent } from './floating-chat/floating-chat.component';
import {SharedModuleModule} from '../../common/shared-module/shared-module.module';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { MessageListComponent } from './floating-chat/message-list/message-list.component';


@NgModule({
  declarations: [FloatingChatComponent, MessageListComponent],
  exports: [
    FloatingChatComponent
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    SharedModuleModule,
    DragDropModule
  ]
})
export class ChatModule { }
