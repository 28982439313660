import { Component } from "@angular/core";
import { BnNgIdleService } from "bn-ng-idle";
import { environment } from "../environments/environment";
import {  OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, first, take, takeWhile } from "rxjs/operators";
import { AuthService } from "./common/services/auth.service";
import { IdleUserDetectorService } from "./common/services/idle-user-detector.service";
import { SocketService } from "./common/services/socket.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { RequesterService } from "./common/services/requester.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  sessionTimeOut$: any;
  changePasswordVisible: boolean = false;
  currentUrl: string = '';
  exceptionUrls = ['/auth/choose-plan', '/auth/payment-success', '/auth/payment-failure'];
  constructor(
    private socketService: SocketService,
    private _bnIdle: BnNgIdleService,
    private _router: Router,
    private _idleUserDetectorService: IdleUserDetectorService,
    private _authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalService: NzModalService,
    private router : Router,
    private requesterService: RequesterService
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        console.log('Current URL:', this.currentUrl);
      }
    } );
  }

  getSessionId(): any {
    return document.cookie.match('(^|;)\\s*JSESSIONID\\s*=\\s*([^;]+)');
  }
  ngOnInit() {
    console.log('App component', this.getSessionId());
    this.changeRouteDetect();
    this.socketService.connect();
    this.socketService.authentication();
    this._authService.startIdleTimer();
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('params FROM APP COMPONENT', this.router.url);
      if(params.changePassword) {
        if(Number(params.changePassword) === 1) {
          this.changePasswordVisible = true;
          this.openChangePasswordModal();
        }
      }
    });

    this._authService.vendorSubscription.subscribe((data) => {
      if(data) {
        if( data.paymentEnabled ) {
          if( !data.subscription.endDate || new Date(data.subscription.endDate) < new Date() ) {
            if(this.exceptionUrls.findIndex((x: any) => this.currentUrl.includes(x)) !== -1) {
              return;
            }
            console.log('NO PLANS ATTACHED')
            const modal = this.modalService.create({
              nzTitle: 'Inactive Subscription',
              nzContent: 'You do not have active Subscription!',
              nzClosable: false,
              nzMaskClosable: false,
              nzOkText: 'Subscribe',
              nzWidth: 300,
              nzOnOk: () => {
                this.router.navigate(['/auth/choose-plan']);
              },
              nzCancelText: 'Later',
            });
          }
        } else {
          console.log('PAYMENT DISABLED')
        }
      }
    } );
  }
//get route in every change --------
  changeRouteDetect():void{
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      console.log('Route changed **************************:', event.url);
      this.requesterService.request('post', 'generals/track-page-view', { page: event.url }).subscribe((apiResponse) => {
        console.log('Page view tracked');
      } , err => {
        console.log('Page view tracking failed');
      } );
    });
  }

  openChangePasswordModal() {
    const modalRef = this.modalService.create({
      nzTitle: 'Set Password',
      nzContent: ChangePasswordComponent,
      nzComponentParams: {
        init: true
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null
    });

    modalRef.afterClose.subscribe(result => {
      this.changePasswordVisible = false;
      this.router.navigate([], { queryParams: { changePassword: null }, queryParamsHandling: 'merge' });
    });
  }


  ngOnDestroy() {
    this._idleUserDetectorService.stop();
 }

}
