import {Component, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {RequesterService} from '../../../common/services/requester.service';
import { ConfigurationService } from '../../../common/services/configuration.service';
import {AuthService} from '../../../common/services/auth.service';

@Component({
  selector: 'app-accreditation',
  templateUrl: './accreditation.component.html',
  styleUrls: ['./accreditation.component.scss']
})
export class AccreditationComponent implements OnInit {
  accreditation: FormGroup;
  companyLoading = false;
  @Output() formData = new EventEmitter();
  constructor(private fb: FormBuilder, private requesterService: RequesterService, private msg: NzMessageService) { }
  attachmentsArray:any[] = [];
  async ngOnInit() {
    console.log('accreditation', this.accreditation);
    this.accreditation = this.fb.group({
      accreditation: this.fb.array([]),
    });
    try {
      const { accreditation } = await this.getMyCompanyDetails();
      if(accreditation && accreditation.length) {
        accreditation.map(x => this.addMoreAccreditation(x));
      } else {
        this.addMoreAccreditation();
      }
    } catch(e) {

    } finally {
      this.formData.emit(this.accreditation);
    }
  }


  createAccreditationArray(data?: any) {
    if (!data) {
      return this.fb.group({
        certification: [null],
        files: [[]]
      });
    } else {
      return this.fb.group({
        certification: [data.certification],
        files: [data.files.map(x => x._id)]
      });
    }

  }

  get accreditationFromArray() {
    return this.accreditation.controls.accreditation as FormArray;
  }
  removeAccreditation(index) {
    this.accreditationFromArray.removeAt(index);

  }

  addMoreAccreditation(data?: any) {
    // this.router.navigateByUrl('auth/addMoreContact');
    this.accreditationFromArray.push(this.createAccreditationArray(data));
    this.attachmentsArray.push(data ? data.files : []);
  }

  onAttachmentsUpdate(index: number, files: any[]) {
    console.log(index, files);
    this.accreditationFromArray.at(index).patchValue({
      files: files.map(x => x._id)
    });
    this.formData.emit(this.accreditation);
  }

  async getMyCompanyDetails(): Promise<any> {
    this.companyLoading = true;
    try {
      const { data } = await this.requesterService.request('get', 'vendor-companies/my-company').toPromise();
      this.companyLoading = false;
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}
