import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {NzUploadFile, NzUploadXHRArgs} from 'ng-zorro-antd/upload';
import {NzMessageService} from 'ng-zorro-antd/message';
import { NzSelectComponent} from 'ng-zorro-antd/select';

import {RequesterService} from 'src/app/common/services/requester.service';
import {countries} from 'src/app/common/data/countries';
import {ConfigurationService} from '../../../common/services/configuration.service';
import {AuthService} from '../../../common/services/auth.service';
import {Observable, Observer, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import { currencies } from '../../../common/data/currencies';
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {
  companyDetails: FormGroup;
  listOfCountry = countries;
  companyName: string;
  avatarUrl = '';
  mediaBaseUrl = ConfigurationService.mediaBaseUrl;
  loading = false;
  listOfPorts: any[] = [];
  portsPage = 1;
  isLoading = false;
  companyId = 0;
  portsListCountryWise: any[] = [];
  selectedPorts: any[] = [];
  @Output() formData = new EventEmitter();
  existingData: any;
  searchPort: string | null = null;
  selectedCountryNames: string[] = [];
  searchPortSubject: Subject<any> = new Subject<any>();
  hoverAvatar = false;
  currencies = currencies;

  serviceSearch: string | null = null;
  serviceSearchObs = new Subject();
  allServices: any[] = [];
  mySelectedServices: string[] = [];
  servicesPage = 1;
  showLoadMoreService = false;
  addServiceTypePopupOpen = false;
  newServiceInput = '';
  addServiceLoading = false;
  perPageLimit = 50;
  constructor(
    private fb: FormBuilder,
    private requesterService: RequesterService,
    private msg: NzMessageService
  ) { }

  ngOnInit() {
    this.companyDetails = this.fb.group({
      address: [null,Validators.required ],
      countryName: [null, ],
      zipCode: [null, ],
      ports: [null, Validators.required],
      invoiceCurrency: [null, Validators.required],
      serviceTypes: [[], [Validators.required]],
    });

    this.formData.emit(this.companyDetails);
    this.getMyCompanyDetails().then( existingPorts => {
      // console.log('existingPorts', existingPorts);
      this.getPorts().then( _ => {
        this.updateSelectedPortsByCountry(existingPorts || []);
      });
    });

    this.companyDetails.controls.ports.valueChanges.subscribe(value => {
      this.updateSelectedPortsByCountry(value || []);
    });

    this.searchPortSubject.pipe(debounceTime(1000)).subscribe(str => {
      this.searchPort = str;
      this.portsPage = 1;
      this.listOfPorts = [];
      this.getPorts();
    });

    this.serviceSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      console.log(searched);
      this.getServices();
    });
  }
  ngOnDestroy(): void {
    AuthService.setSection('basicDetails', this.companyDetails.value);
  }

  getServices(more = false) {
    this.isLoading = true;
    if (more) {
      this.servicesPage++;
    } else {
      this.allServices = [];
      this.servicesPage = 1;
    }
    const params: any = {
      skip: ( this.servicesPage - 1 ) * this.perPageLimit,
      limit: this.perPageLimit
    };
    if (this.serviceSearch) {
      params.search = this.serviceSearch;
    }
    this.requesterService.request('get', `master-resources/services`, params).subscribe(data => {
      this.showLoadMoreService = data.data.length >= this.perPageLimit;
      this.allServices = [...this.allServices, ...data.data];
      this.isLoading = false;
      this.allServices.forEach( item => {
        item.selected = this.mySelectedServices.includes( item.serviceName );
      });
    });
  }

  addToMyServices(pos: number) {
    const itemToPush = { ...this.allServices[pos] };
    const found = this.mySelectedServices.indexOf(itemToPush.serviceName);
    if (found === -1) {
      this.mySelectedServices.push(itemToPush.serviceName);
    } else {
      this.mySelectedServices.splice(found, 1);
    }
    this.updateSelectedServiceTypes();
  }

  removeFromMyServices(pos: number) {
    const itemToRevert = this.mySelectedServices[pos];
    const foundIndex = this.allServices.findIndex(x => x.serviceName === itemToRevert);
    this.mySelectedServices.splice(pos, 1);
    if (foundIndex !== -1) {
      this.allServices[foundIndex].selected = false;
    }
    this.updateSelectedServiceTypes();
  }

  updateSelectedServiceTypes() {
    this.companyDetails.patchValue({ serviceTypes: this.mySelectedServices });
    this.allServices.map( x => x.selected = this.mySelectedServices.includes(x.serviceName));
  }

  async addService(): Promise<void> {
    try {
      const {data} = await this.requesterService
      .request('post', 'master-resources/services', {serviceName: this.newServiceInput}).toPromise();
      // this.mySelectedServices.push(this.newServiceInput);
      this.allServices.push(data);
      this.addToMyServices(0);
    } catch (e) {

    }

  }

  onServiceSearch(e) {
    console.log('on Service change', e);
    this.serviceSearchObs.next(e);
    this.newServiceInput = e;
  }

  getMyCompanyDetails(): Promise<any> {
    return new Promise<any>(resolve => {
      this.requesterService.request('get', 'vendor-companies/my-company').subscribe(data => {
        const company = data.data;

        this.mySelectedServices = company.skills && company.skills.serviceTypes ?
        company.skills.serviceTypes : [];
        this.updateSelectedServiceTypes();
        this.getServices();
        // console.log( company );
        this.companyName = company.companyName;
        this.companyId = company._id;
        this.existingData = company.basicDetails;
        this.existingData.invoiceCurrency = company.bankDetails?.invoiceCurrency;
        this.existingData.serviceTypes = this.mySelectedServices;
        // console.log(this.existingData);
        if (this.existingData) {
          this.companyDetails.patchValue({ ...this.existingData});
          this.avatarUrl = this.existingData.companyLogo;
          // this.updateSelectedPortsByCountry();
        } else {

        }
        if (this.existingData && this.existingData.ports) {
          resolve(this.existingData.ports);
        } else {
          resolve([]);
        }
      }, error => resolve([]));
    });
  }

  customReq = (item: NzUploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('file', item.file as any);
    this.loading = true;
    return this.requesterService.fileRequest('media', formData).subscribe(
      // tslint:disable-next-line no-any
      (event) => {
        this.avatarUrl = event.data.path;
        this.loading = false;
        this.msg.success('Logo uploaded successfully');
        this.companyDetails.patchValue({
          companyLogo: event.data.path
        });
      },
      err => {
        this.loading = false;
        this.msg.error(err.error.message);
      }
    );
  }

  beforeUpload = (file: NzUploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // console.log(file);
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.msg.error('You can only upload JPG file!');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      if (!(isJpgOrPng && isLt2M)) {
        observer.next(isJpgOrPng && isLt2M);
        observer.complete();
      }
      const URL = window.URL;
      const Img = new Image();
      Img.src = URL.createObjectURL(file as any);
      Img.onload = (e: any) => {
        const height = e.path[0].height;
        const width = e.path[0].width;

        observer.next(true);
        observer.complete();
        // console.log(height, width);
        // if (height > 500 || width > 500 || height < 100 || width < 100) {
        //   this.msg.error('Image dimension must be between 100x100 - 500x500');
        //   observer.next(false);
        //   observer.complete();
        // } else {

        // }
      };
    });
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    // @ts-ignore Forbidden non null assertion
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  getPorts(): Promise<any> {
    return new Promise<void>(resolve => {
      this.isLoading = true;
      const params: any = {
        page: this.portsPage
      };
      if (this.existingData && this.existingData.ports && this.existingData.ports.length) {
        params.existing = this.existingData.ports;
      }
      if (this.searchPort) {
        params.search = this.searchPort;
      }
      // console.log(params, 'PARAMS');
      this.requesterService.request('post', `master-resources/ports/ports-by-country`, params)
        .subscribe(portsData => {
        this.listOfPorts = [ ...this.listOfPorts, ...portsData.data.map(x => {x.checked = !!x.checked; return x; })];
        // console.log(this.listOfPorts);
        this.isLoading = false;
        resolve();
      }, err => {
        resolve();
      });
    });
  }

  loadMorePorts() {
    this.portsPage++;
    this.getPorts();
  }
  searchPorts(str: string) {
    this.searchPortSubject.next(str.trim() || null);
  }
  onOperationCountryChange() {
    // console.log(this.companyDetails.value.portCoveredCountryName);
    const selectedCountryCodes: string[] = this.companyDetails.value.portCoveredCountryName || [];
    this.selectedCountryNames = countries.filter(x => selectedCountryCodes.includes(x.code)).map(x => x.name);
  }

  openSelect(el: NzSelectComponent) {
    el.setOpenState(true);
  }

  onCountrySelect(e, index) {
    // console.log(e, index);
    if (e) {
      const portsAlreadySelected = this.companyDetails.value.ports || [];
      this.listOfPorts[index].ports.forEach(port => {
        if (!portsAlreadySelected.includes(port._id)) {
          portsAlreadySelected.push(port._id);
        }
      });
      this.companyDetails.patchValue({ports: portsAlreadySelected});
    } else {
      const portsAlreadySelected = this.companyDetails.value.ports || [];
      this.listOfPorts[index].ports.forEach(port => {
        const foundIndex = portsAlreadySelected.indexOf(port._id);
        if (foundIndex !== -1) {
          portsAlreadySelected.splice(foundIndex, 1);
        }
      });
      this.companyDetails.patchValue({ports: portsAlreadySelected});
    }
  }

  updateSelectedPortsByCountry(existingPortNames: any[]) {
    // console.log('EXISTING PORTS', existingPortNames);
    this.listOfPorts.forEach(country => {
      country.checked = country.ports.every(x => existingPortNames.includes(x._id));
    });
  }

  zeroPad(n, width) {
    const z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  deleteLogo() {
    this.companyDetails.patchValue({companyLogo: ''});
    this.avatarUrl = null;
  }
}
