import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RequesterService} from './requester.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  poId: string = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private requesterService: RequesterService
  ) {
    this.router.events.subscribe(event => {
      // console.log(event)
      if (event instanceof NavigationEnd) {
        this.populateBreadcrumb(event.urlAfterRedirects);
      }
    });
  }
  private async populateBreadcrumb(url: string) {
    console.log(url);
    const breadcrumbData = await this.getBreadcrumbArrayByUrl(url);
    this.breadcrumbs$.next(breadcrumbData);
  }
  public get onBreadcrumb(): Observable<any[]> {
    return this.breadcrumbs$.asObservable();
  }

  private async getBreadcrumbArrayByUrl(url: string): Promise<any[]> {
    const finalBreadcrumb: any[] = [];
    if (url === '/panel/dashboard') {
      return [];
    }
    let rfqId = null;
    finalBreadcrumb.push({
      url: '/panel/dashboard',
      name: 'Dashboard'
    });
    // Insert Job Details related Routes
    if (/\/panel\/bidding\/.*\/.*\/?/.test(url)) {
      rfqId = url.split('/')[3];
    } else if (/\/panel\/work-space\/.*\/?/.test(url)) {
      rfqId = url.split('/').pop();
    }
    let pages = [];

    if (rfqId) {
      const rfqDetails = await this.getRfqDetails(rfqId);
      console.log(rfqDetails);
      this.poId = rfqDetails.poId;
      finalBreadcrumb.push({
        url: `/panel/bidding/${rfqId}/rfq-summary`,
        name: 'Job Details'
      });
      pages = [
        {
          url: `/panel/bidding/${rfqId}/submit-bid`,
          name: 'Bid Details',
          key: 'submit-bid',
          status: rfqDetails.bid,
          active: false
        },
        {
          url: `/panel/bidding/${rfqId}/view-po/${this.poId}`,
          name: 'PO Details',
          key: 'view-po',
          status: rfqDetails.po,
          active: false
        },
        {
          url: `/panel/work-space/${rfqId}`,
          name: 'Workspace',
          key: 'work-space',
          status: rfqDetails.workspace,
          active: false
        },
        {
          url: `/panel/bidding/${rfqId}/invoice`,
          name: 'Invoice',
          key: 'invoice',
          status: rfqDetails.invoice,
          active: false
        },
        {
          url: `/panel/bidding/${rfqId}/attachments`,
          name: 'Attachments',
          key: 'attachments',
          status: true,
          active: false
        },
      ];
    }



    if (/\/panel\/bidding\/.*\/rfq-summary\/?/.test(url)) {
      finalBreadcrumb.push({
        url: null,
        name: 'Select',
        children: pages
      });
    }
    if (/\/panel\/work-space\/.*\/?/.test(url)) {
      const foundIndex = pages.findIndex(x => x.key === 'work-space');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }


    if (/\/panel\/bidding\/.*\/invoice\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'invoice');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    if (/\/panel\/bidding\/.*\/view-po\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'view-po');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    if (/\/panel\/bidding\/.*\/submit-bid\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'submit-bid');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    if (/\/panel\/bidding\/.*\/attachments\/?/.test(url)  ) {
      const foundIndex = pages.findIndex(x => x.key === 'attachments');
      if (foundIndex !== -1) {
        pages[foundIndex].active = true;
        finalBreadcrumb.push({
          url: null,
          name: pages[foundIndex].name,
          children: pages
        });
      }
    }

    return finalBreadcrumb;
  }

  async getRfqDetails(rfqId): Promise<any> {
    return (await this.requesterService.request('get', 'jobs/rfqs/' + rfqId + '/breadcrumbs').toPromise()).data;
  }
}
