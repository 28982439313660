import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vendorStatus'
})
export class VendorStatusPipe implements PipeTransform {

  transform(rfqData: any): string {
    if (!rfqData) {
      return null;
    }
    switch (rfqData.jobStatus) {
      case 'Awaiting Quotes' :
        return rfqData.bid && rfqData.bidStatus !== 'Draft' ? 'Submitted' :  'Awaiting Bid';
      case 'Quotes Received' :
        return rfqData.resubmissionRequested ? 'Resubmission Requested' : 'Submitted';
      case 'Awaiting PO' :
        return rfqData.resubmissionRequested ? 'Resubmission Requested' : 'Submitted';
      case 'Awaiting Confirmation' :
        return rfqData.poApprovedByAdmin ? 'Awaiting Confirmation' : 'Submitted';
      case 'Awarded' :
        return rfqData.bidStatus === 'No Bid' ? 'No Bid' : 'Job Lost';
      case 'Completed' :
        return rfqData.serviceReport
          ? rfqData.serviceReport.status === 'rejected' ? 'SR Rejected' : 'SR Generated'
          : 'Awaiting SR';
      case 'Invoice Pending':
        return rfqData.invoiceResubmitted
          ? 'Invoice Sent'
          : rfqData.invoiceRejected
            ? 'Invoice Rejected'
            : rfqData.invoiceGenerated ? 'Invoice Sent' : rfqData.jobStatus;
      default: return rfqData.jobStatus;
    }
  }

}
