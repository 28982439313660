import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localFilter'
})
export class LocalFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, key?: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      if (key) {
        return it[key] && it[key].toLowerCase().includes(searchText);
      }
      return it.toLowerCase().includes(searchText);
    });
  }

}
