import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-public',
  templateUrl: './privacy-policy-public.component.html',
  styleUrls: ['./privacy-policy-public.component.scss']
})
export class PrivacyPolicyPublicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
