// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.attender.ai',
  mediaBaseUrl: 'https://api.attender.ai/uploads/',
  sessionTimeOutInSeconds: 7200,
  stripeUrl :  'https://paymentprod.attender.ai',
  stripePublicKey : 'pk_live_51P1MKNItVaMpopZmz3qnFurAWQQqTA7BGuOXKDneslc6HGWCEk6PM2NFHgjnMJNEGOhrrw2whzRFD9HxcFgAiFCK00C84W4wFn',
  googleLoginPublicKey : '20431365903-hr67dnv6a62fifdjnt5u5f6uabm0v4uc.apps.googleusercontent.com',
  streamChat: {
    apiKey: 'zqstrzyy75ve',
    appId: '1125142',
  },
  clarityProjectId: 'q1t76ci6dy'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
