import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageShareService {
  messageSubject = new Subject<any>();
  constructor() { }

  getMessage(): Observable<any> {
    return this.messageSubject.asObservable();
  }


  sendMessage(message) {
    return this.messageSubject.next(message);
  }

  destroy() {
    this.messageSubject.unsubscribe();
  }


}
