import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByDate'
})
export class FilterByDatePipe implements PipeTransform {

  transform(dataArray: any[], dates: string[] | Date[]): any {
    if (!dataArray || !dataArray.length) {
      return [];
    }
    if (dates.length !== 2) {
      return dataArray;
    }
    // @ts-ignore
    const formattedDates = dates.map(x => new Date(x));
    const filteredArray = dataArray.filter(x => {
      return Object.keys(x).some(key => {
        if (typeof(x[key]) === 'string' && /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i.test(x[key])) {
          return new Date(x[key]) >= formattedDates[0] && new Date(x[key]) <= formattedDates[1];
        } else {
          return false;
        }
      });
    });
    return filteredArray;
  }

}
