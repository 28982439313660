import {Component, OnInit, EventEmitter, Output, OnDestroy} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageShareService } from '../shared/message-share.service';
import { RequesterService } from 'src/app/common/services/requester.service';
import { currencies } from '../../../common/data/currencies';
import {AuthService} from '../../../common/services/auth.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit, OnDestroy {
  paymentDetails: FormGroup;
  billingAddressCheckbox: boolean;
  taxNumberCheckbox: boolean;
  vatNumberCheckbox: boolean;
  @Output() formData = new EventEmitter();
  existingData: any = {};
  currencies = currencies;
  paymentTerms: any = {};
  constructor(
    private fb: FormBuilder,
    private messageService: MessageShareService,
    private requesterService: RequesterService) { }

  async ngOnInit() {
    this.paymentDetails = this.fb.group({
      taxNotApplicabble: [null],
      vatNotApplicable: [null],
      showBillingAddress: [false, Validators.required],
      billingAddress: [null],
      beneficiaryName: [null],
      bankName: [null],
      bankAddress: [null],
      bankAccountNumber: [null],
      swiftCode: [null],
      ifscCode: [null],
      ibanCode: [null],
      routingCode: [null],
      invoiceCurrency: [null, Validators.required],
      paymentCurrency: [null, Validators.required],

      branchCode: [null],

    });

    this.formData.emit(this.paymentDetails);
    try {
      const { bankDetails } = await this.getMyCompanyDetails();
      if (bankDetails) {
        this.paymentDetails.patchValue({ ...bankDetails, paymentCurrency: bankDetails.invoiceCurrency });
      }

    } catch (error) {

    }

  }
  ngOnDestroy(): void {
    AuthService.setSection('bankDetails', this.paymentDetails.value);
  }

  requiredChange(event) {
    // console.log('checkbox', event);
    this.billingAddressCheckbox = event;
    // // console.log('formControl name',this.paymentDetails.controls['showkBillingAddress']);
  }
  taxNumberCheck(event) {
    this.taxNumberCheckbox = event;
    // console.log('tax checkbox', this.taxNumberCheckbox);
    if (event) {
      this.paymentDetails.patchValue({ taxNumber: 'N/A' });
    } else {
      this.paymentDetails.patchValue({ taxNumber: '' });
    }
  }
  vatNumberCheck(event) {
    this.vatNumberCheckbox = event;
    // console.log('vat Checkbox', this.vatNumberCheckbox);
    if (event) {
      this.paymentDetails.patchValue({ vatNumber: 'N/A' });
    } else {
      this.paymentDetails.patchValue({ vatNumber: '' });
    }
  }


  async getMyCompanyDetails(): Promise<any> {
    try {
      const { data } = await this.requesterService.request('get', 'vendor-companies/my-company').toPromise();
      return data;
    } catch (e) {
      // console.log(e);
    }
  }

  onInvoiceCurrencyChange() {
    this.paymentDetails.patchValue({
      paymentCurrency: this.paymentDetails.value.invoiceCurrency
    });
  }

}
