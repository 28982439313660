import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pdf-preview-render',
  templateUrl: './pdf-preview-render.component.html',
  styleUrls: ['./pdf-preview-render.component.scss']
})
export class PdfPreviewRenderComponent implements OnInit {
@Input() filePath : any;
  filePathUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.filePathUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + '/uploads/'+ this.filePath);
  }
}
