import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByArrayField'
})
export class FilterByArrayFieldPipe implements PipeTransform {

  transform(dataArray: any[], key: string, values: string[]): any {
    if (!dataArray.length) {
      return dataArray;
    }
    return  dataArray.filter(x => {
      if (Array.isArray(x[key])) {
        return x[key].some(item => values.includes(item));
      } else {
        return values.includes(x[key]);
      }
    });
  }

}
