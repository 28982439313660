import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { RequesterService } from '../../services/requester.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-quick-settings-modal',
  templateUrl: './quick-settings-modal.component.html',
  styleUrls: ['./quick-settings-modal.component.scss']
})
export class QuickSettingsModalComponent implements OnInit {

  @Input() sections: number[] = [];
  sectionWiseNames: any[] = [
    {
      section: 1,
      name: 'General'
    },
    {
      section: 2,
      name: 'Terms & Conditions'
    },
    {
      section: 3,
      name: 'Equipment & Brands'
    },
    {
      section: 4,
      name: 'Accreditation'
    },
    {
      section: 6,
      name: 'Bank'
    }
  ]
  companyDetailsForm: FormGroup;
  companySkillForm: FormGroup;
  paymentDetailsForm: FormGroup;
  accreditationForm: FormGroup;
  paymentAndTermsForm: FormGroup;
  current = 0;
  pages: number[] = [];
  currentSections: any[] = [];
  companyId: string = '';
  myCompanyData: any = null;

  get isMandatory(): boolean {
    return this.currentSection === 1 || this.currentSection === 2;
  }
  constructor(
    private modalRef: NzModalRef,
    private requesterService: RequesterService,
    private notification: NzNotificationService
  ) { }

  ngOnInit(): void {
    this.getMyCompanyDetails();
    this.pages = Array.from(Array(this.sections.length).keys());
    // Add Page info to sections
    this.sectionWiseNames = this.sectionWiseNames.map(section => {
      section.page = this.sections.indexOf(section.section);
      return section;
    });
    console.log(this.sectionWiseNames);
    this.currentSections = this.sectionWiseNames.filter(section => this.sections.includes(section.section));
  }

  async getMyCompanyDetails(): Promise<any> {
    try {
      const { data } = await this.requesterService.request('get', 'vendor-companies/my-company').toPromise();
      this.myCompanyData = data;
      this.companyId = data._id;
    } catch (e) {
      console.log(e);
    }
  }

  companyDetailsFormData(event: any) {
    this.companyDetailsForm = event;
  }
  paymentDetailsFormData(event: any) {
    this.paymentDetailsForm = event;
  }
  accreditationFormData(event: any) {
    this.accreditationForm = event;
  }
  companySkillFormData(event: any) {
    this.companySkillForm = event;
  }
  paymentAndTermsFormData(event: any) {
    this.paymentAndTermsForm = event;
  }

  get currentSection(): number {
    return this.currentSections[this.current].section;
  }

  async nextSection() {
    try {
      switch (this.currentSection) {
        case 1:
          await this.handleCompanyDetailsForm();
          break;
        case 2:
          await this.handlePaymentAndTermsForm();
          break;
        case 3:
          await this.handleCompanySkillForm();
          break;
        case 4:
          await this.handleAccreditationForm();
          break;
        case 6:
          await this.handlePaymentDetailsForm();
          break;
        default:
          break;
      }
      this.current++;
      if(this.current >= this.currentSections.length) {
        this.modalRef.close({
          success: true
        });
      }
    } catch (error) {
      console.log(error);
      this.notification.error( 'Error', error);
      // this.modalRef.close({ skip: true });
    }

  }

  skip() {
    this.current++;
    if(this.current >= this.currentSections.length) {
      this.modalRef.close({
        skip: true
      });
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /************************** Form Handlers *********************/
  /**
   * Company Details Form Handler with API call
   */
  handleCompanyDetailsForm(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (!this.companyDetailsForm.valid) {
        this.markFormGroupTouched(this.companyDetailsForm);
        reject('Please fill all the required fields');
      } else {
        // console.log('Valid Company Details Form');
        // console.log(this.companyDetailsForm.value);
        this.requesterService.request('put', `vendor-companies/${this.companyId}/basic-details`, this.companyDetailsForm.value)
          .subscribe(response => {
            this.notification.create(
              'success',
              'SUCCESS',
              response.message,
            );
            resolve();
          });

      }
    });
  }

  handleCompanySkillForm(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (!this.companySkillForm.valid) {
        this.markFormGroupTouched(this.companySkillForm);
        reject('Please fill all the required fields');
      } else {
        this.requesterService.request('put', `vendor-companies/${this.companyId}/skill-details`, this.companySkillForm.value)
          .subscribe(response => {
            this.notification.create(
              'success',
              'SUCCESS',
              response.message,
            );
            resolve();
          });
      }
    });
  }


  handlePaymentDetailsForm(): Promise<any> {
    console.log('payment details form', this.paymentDetailsForm);
    return new Promise<void>((resolve, reject) => {
      if (!this.paymentDetailsForm.valid) {
        this.markFormGroupTouched(this.paymentDetailsForm);
        reject('Please fill all the required fields');
      } else {
        this.requesterService.request('put', `vendor-companies/${this.companyId}/bank-details`, this.paymentDetailsForm.value)
          .subscribe(response => {
            this.notification.create(
              'success',
              'SUCCESS',
              response.message,
            );
            resolve();
          });
      }
    });
  }

  handleAccreditationForm(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (!this.accreditationForm.valid) {
        this.markFormGroupTouched(this.accreditationForm);
        reject('Please fill all the required fields');
      } else {
        const { accreditation } = this.accreditationForm.value
        this.requesterService.request('put', `vendor-companies/${this.companyId}/accreditation-details`, accreditation)
          .subscribe(response => {
            this.notification.create(
              'success',
              'SUCCESS',
              response.message,
            );
            resolve();
          });
      }
    });
  }

  handlePaymentAndTermsForm(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (!this.paymentAndTermsForm.value.checkTermAndCondition) {
        reject('Please accept the terms and conditions');
      } else {
        this.requesterService.request('put', `vendor-companies/${this.companyId}/payment-terms`, this.paymentAndTermsForm.value)
          .subscribe(response => {
            this.notification.create(
              'success',
              'SUCCESS',
              response.message,
            );
            resolve();
          });
      }
    });
  }

}
