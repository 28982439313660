import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequesterService } from '../../../common/services/requester.service';
import jsPDF from 'jspdf';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuthService } from 'src/app/common/services/auth.service';


@Component({
  selector: 'app-payments-and-terms',
  templateUrl: './payments-and-terms.component.html',
  styleUrls: ['./payments-and-terms.component.scss']
})
export class PaymentsAndTermsComponent implements OnInit {
  paymentAndTerm: FormGroup;
  termAndConditionCheck: boolean = true;
  existingData: any = {};
  isBreakpoint = false;
  myCompanyLoading: boolean = false;
  @ViewChild('pdfElement', { static: false }) pdfElement!: ElementRef;
  subscriptionPlan: any;
  constructor(
    private fb: FormBuilder,
    private requesterService: RequesterService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService
    ) {

    this.breakpointObserver.observe([
      "(max-width: 650px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isBreakpoint = true;

      } else {
        this.isBreakpoint = false;
      }

    });
  }

  @Output() formData = new EventEmitter();

  ngOnInit() {
    this.paymentAndTerm = this.fb.group({
      spoolifyFees: [null],
      paymentInDays: [null],
      checkTermAndCondition: [false, [Validators.required]],
    });
    this.formData.emit(this.paymentAndTerm);
    this.getMyCompanyDetails();
    this.getMySubscription();
  }

  getMySubscription(): void {
    this.requesterService.request('get', 'payments/stripe/payment-status')
      .subscribe(response => {
        this.subscriptionPlan = response.data;
        this.subscriptionPlan.isActive = this.subscriptionPlan.subscription.endDate && new Date(this.subscriptionPlan.subscription.endDate) > new Date();
      });
  }
  updateAllChecked(event) {
    console.log(this.paymentAndTerm);
    this.termAndConditionCheck = event;
  }

  getMyCompanyDetails() {
    this.myCompanyLoading = true;
    this.requesterService.request('get', 'vendor-companies/my-company').subscribe(data => {
      this.existingData = data.data.termsAndConditions;
      if (this.existingData) {
        this.paymentAndTerm.patchValue(this.existingData);
      }
      this.myCompanyLoading = false;
    });
  }

  public downloadAsPDF() {
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.addFont('ArialMS', 'Arial', 'normal');
    doc.setFont('Arial');

    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    console.log(this.pdfElement);
    const pdfElement = this.pdfElement.nativeElement;

    doc.fromHTML(pdfElement.innerHTML, 15, 15, {
      width: 540,
      // 'elementHandlers': specialElementHandlers
    });

    doc.save('Attender Vendor Membership Agreement.pdf');
  }
}
