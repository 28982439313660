import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequesterService } from './requester.service';

@Injectable({
  providedIn: 'root'
})
export class VendorStatusService {
  public $status: Subject<any> = new Subject<any>();
  public $statusChange: Subject<any> = new Subject<any>();
  constructor(private requesterService: RequesterService) {
    this.$statusChange.subscribe(() => {
      this.requesterService.request('get', 'vendor-companies/my-company').subscribe(response => {
        this.$status.next({vendorStatus: response.data.vendorStatus, vendorStatusText: response.data.vendorStatusText})
      })
    })

  }
}
