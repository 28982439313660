import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {
  @Input() messages: any[] = [];
  @Input() loading: boolean = false;
  @Input() currentUser: any = {};
  gridStyle = {
    width: '50%',
    height: '100px',
    textAlign: 'center',
    padding: '5px'
  };
  constructor() { }

  ngOnInit() {
  }

}
