import { Injectable } from '@angular/core';
import { data } from './menu.data';
@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() { }

  getLeftMenu(include?: string[]): any[] {
    // console.log(include);
    if (!include || include.length === 0) {
      return data;
    } else {
      return data.filter(x => include.includes(x.key));
    }
  }
}
