import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { RequesterService } from "../../../../common/services/requester.service";
import * as format from "date-fns/format";
import { StatusService } from "src/app/common/services/status.service";
import * as moment from "moment";
import { Router } from "@angular/router";
@Component({
  selector: "app-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.scss"],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  pageSize = 3;
  @Input() searchText: Observable<string>;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClear: EventEmitter<boolean> = new EventEmitter<boolean>();
  dateFormat = "dd/MM/yyyy";
  dateFilter: any[] = [];
  dateArray: string[];
  listOfSelectedStatus: string[] = [];

  listOfSelectedPorts: string[] = [];
  keyword: string;
  subscription: Subscription;
  advanceSearch = false;
  isShowingRFQDetails = false;
  rfqDetailsCheckLoader = false;
  searchResults: any = {
    rfqs: {
      name: "Jobs",
      loading: true,
      data: [],
      display: true,
    },
    pos: {
      name: "POs",
      loading: true,
      data: [],
      display: true,
    },
    invoices: {
      name: "Invoices",
      loading: true,
      data: [],
      display: true,
    },
  };

  allResults: any = {};
  allRfqs: any[] = [];
  allBids: any[] = [];
  allPos: any[] = [];
  allInvoices: any[] = [];
  allChats: any[] = [];
  allServices: any[] = [];
  allEquipments: any[] = [];
  allBrands: any[] = [];
  searchLoading = false;
  rfqDetailsArr = [];

  selectedVendors: string[] = [];

  portsSet: Set<string> = new Set();
  vendorsSet: Set<string> = new Set();
  ownerSet: Set<string> = new Set();
  jobStatusSet: Set<string> = new Set();
  // Filters
  allPorts: string[] = [];
  selectedPorts: string[] = [];
  getPorts$: Subject<string> = new Subject();

  jobStatusList: any[] = [];
  selectedJobStatusList: any[] = [];

  allVendors: string[] = [];
  selectedVendorList: string[] = [];
  getAllVendors$: Subject<string> = new Subject();

  constructor(
    private requesterService: RequesterService,
    public statusService: StatusService,
    private router: Router
  ) {
    this.getPorts$.pipe(debounceTime(500)).subscribe((keywords) => {
      this.getAllPorts(keywords);
    });
    this.getAllVendors$.pipe(debounceTime(500)).subscribe((keywords) => {
      this.getAllVendors(keywords);
    });
    this.jobStatusList = Object.entries(this.statusService.jobStatus).map(([key, value]) => ({label: value, value: key}));
    const index = this.jobStatusList.findIndex(x => x.label === 'Awaiting PO Confirmation');
    this.jobStatusList.splice(index, 1);
  }

  get isNoData(): boolean {
    return Object.values(this.allResults).every((x: any) => !x.length);
  }

  get isLoading(): boolean {
    return Object.values(this.allResults).some((x: any) => x.loading);
  }

  get listOfPorts(): string[] {
    return Array.from(this.portsSet);
  }

  get listOfVendors(): string[] {
    return Array.from(this.vendorsSet);
  }

  get listOfOwner(): string[] {
    return Array.from(this.ownerSet);
  }

  get listOfJobStatus(): string[] {
    return Array.from(this.jobStatusSet);
  }

  ngOnInit() {
    this.subscription = this.searchText
      .pipe(debounceTime(1000))
      .subscribe(async (keywords) => {
        this.keyword = keywords;
        // console.log('KEYWORD', this.keyword)
        await this.searchByFilters();
      });

      this.getAllPorts();
      this.getAllVendors();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getAllPorts(search?: string): void {
    const params: any = {};
    if(search) {
      params.search = search;
    }
    this.requesterService.request('get', 'master-resources/ports', params)
      .toPromise()
      .then((res) => {
        console.log('PORTS', res);
        this.allPorts = res.data.map(x => x.name);
      });
  }

  getAllVendors(search?: string): void {
    const params: any = {};
    if(search) {
      params.search = search;
    }
    this.requesterService.request('get', 'owner-companies/active-owners', params)
      .toPromise()
      .then((res) => {
        this.allVendors = res.data.map(x => x.companyName);
      });
  }

  clearFilters(): void {
    this.selectedPorts = [];
    this.selectedVendorList = [];
    this.selectedJobStatusList = [];
    this.dateFilter = [];
  }

  async searchByFilters() {
    const params: any = { search: this.keyword };
    if (!params.search) {
      return;
    }
    const dateArr = this.dateFilter.map((x) => moment(x).format("YYYY-MM-DD"));
    if (dateArr.length) {
      params.startDate = dateArr[0];
      params.endDate = dateArr[1];
    }
    // Other Filters
    if (this.selectedPorts.length) {
      params.ports = this.selectedPorts;
    }
    if (this.selectedJobStatusList.length) {
      params.status = this.selectedJobStatusList.map(x => Number(x));
    }
    if (this.selectedVendorList.length) {
      params.owners = this.selectedVendorList;
    }

    if (localStorage.getItem("searchKeyword")) {
      params.search = localStorage.getItem("searchKeyword");
      if (
        localStorage.getItem("searchParams") &&
        Object.keys(JSON.parse(localStorage.getItem("searchParams"))).length
      ) {
        params.startDate = JSON.parse(
          localStorage.getItem("searchParams")
        ).startDate;
        params.endDate = JSON.parse(
          localStorage.getItem("searchParams")
        ).endDate;
        this.dateFilter = [
          new Date(params.startDate),
          new Date(params.endDate),
        ];
      }
      this.clearSearch();
    }
    this.searchLoading = true;
    const result = await this.requesterService
      .request("post", "generals/search", params)
      .toPromise();

    this.allResults = result.data;
    this.allRfqs = result.data.rfqs;
    this.allBids = result.data.bids;
    this.allPos = result.data.pos;
    this.allInvoices = result.data.invoices;
    this.allChats = result.data.messages;
    this.allServices = result.data.services;
    this.allEquipments = result.data.equipments;
    this.allBrands = result.data.brands;
    this.searchLoading = false;
    this.initLoadFilters();
    const resultNLP = await this.requesterService
      .request("post", "generals/search-by-nlp", params)
      .toPromise();
    this.allRfqs = [
      ...this.allRfqs,
      ...resultNLP.data.rfqs.filter(
        (x) => !this.allRfqs.map((r) => r.rfqNo).includes(x.rfqNo)
      ),
    ];
    this.allBids = [
      ...this.allBids,
      ...resultNLP.data.bids.filter(
        (x) => !this.allBids.map((r) => r._id).includes(x._id)
      ),
    ];
    this.allPos = [
      ...this.allPos,
      ...resultNLP.data.pos.filter(
        (x) => !this.allPos.map((r) => r._id).includes(x._id)
      ),
    ];
    this.allInvoices = [
      ...this.allInvoices,
      ...resultNLP.data.invoices.filter(
        (x) => !this.allInvoices.map((r) => r._id).includes(x._id)
      ),
    ];
    this.allChats = [
      ...this.allChats,
      ...resultNLP.data.messages.filter(
        (x) => !this.allChats.map((r) => r._id).includes(x._id)
      ),
    ];
    this.allServices = [
      ...this.allServices,
      ...resultNLP.data.services.filter(
        (x) => !this.allServices.map((r) => r._id).includes(x._id)
      ),
    ];
    this.allBrands = [
      ...this.allBrands,
      ...resultNLP.data.equipments.filter(
        (x) => !this.allBrands.map((r) => r._id).includes(x._id)
      ),
    ];
    this.allBrands = [
      ...this.allBrands,
      ...resultNLP.data.brands.filter(
        (x) => !this.allBrands.map((r) => r._id).includes(x._id)
      ),
    ];
    this.onDataLoaded();
  }

  onDataLoaded(): void {
    // Update Meta data
    this.allRfqs.forEach((x) => {
      x.ports.map((y) => this.portsSet.add(y));
      x.selectedVendors.map((y) => this.vendorsSet.add(y));
      this.jobStatusSet.add(x.jobStatus.toString());
    });

    this.allBids.forEach((x) => {
      this.portsSet.add(x.port);
      this.vendorsSet.add(x.vendorCompany);
    });

    this.listOfSelectedPorts = Array.from(this.portsSet);
    this.selectedVendors = Array.from(this.vendorsSet);
    this.listOfSelectedStatus = Array.from(this.jobStatusSet);

  }

  initLoadFilters() {
    this.listOfSelectedPorts = [];
    this.listOfSelectedStatus = [];
    this.selectedVendors = [];
  }

  close() {
    localStorage.setItem("searchKeyword", this.keyword);
    const dateArr = this.dateFilter.map((x) => moment(x).format("YYYY-MM-DD"));
    const params: any = {};
    if (dateArr.length) {
      params.startDate = dateArr[0];
      params.endDate = dateArr[1];
      localStorage.setItem("searchParams", JSON.stringify(params));
    }
    this.onClose.emit(true);
  }

  clearSearch() {
    localStorage.removeItem("searchParams");
    localStorage.removeItem("searchKeyword");
  }

  async onClearEmit() {
    this.keyword = "";
    this.onClear.emit(true);
    await this.searchByFilters();
  }

  resultInChange(section: string, enabled: boolean) {
    this.searchResults[section].display = enabled;
  }

  async rfqDetailsShow(rfqs) {
    let params = { search: rfqs.join(" "), sections: "rfq" };
    try {
      this.rfqDetailsCheckLoader = true;
      const result = await this.requesterService
        .request("post", "generals/search", params)
        .toPromise();
      this.rfqDetailsArr = result.data.rfqs;
      this.isShowingRFQDetails = true;
      this.rfqDetailsCheckLoader = false;

    } catch (error) {
      this.rfqDetailsCheckLoader = false;
    }
  }
  onBack() {
    this.isShowingRFQDetails = false;
  }

  openChat(data: any) {
    if(data.chatWith=== 'owner'){
      this.router.navigateByUrl(`/main/chats?rfq=${data.rfq}&type=owner`);
    } else if(data.chatWith=== 'vendor'){
      const vendor = data.channelId.split('_vendor_')[1];
      this.router.navigateByUrl(`/main/chats?rfq=${data.rfq}&type=vendor&vendor=${vendor}`);
    }
    this.close();
  }

}
